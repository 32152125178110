<template>
  <div class="w-full overflow-hidden">
    <div class="flex items-center space-x-5">
      <div>
        <h2
          class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200"
        >
          Payments Log
        </h2>
      </div>
    </div>
    <div
      v-if="auth.level > 10"
      class="flex items-center justify-center my-2 space-x-2"
    >
      <div
        class="px-2 py-1 font-semibold tracking-wide text-left uppercase border rounded-xl"
        v-for="cafe in cafes"
        :key="cafe.nano_id"
        :class="
          cafe_id === cafe.id
            ? 'text-green-700 bg-green-100 dark:bg-green-700 dark:text-green-100'
            : 'text-gray-500  border-gray-400 dark:border-gray-400 bg-gray-200 dark:text-gray-400 dark:bg-gray-700'
        "
      >
        <button @click="setCafeFilter(cafe)">{{ cafe.name }}</button>
      </div>
    </div>
    <div
      class="overflow-hidden border border-gray-300 rounded-xl dark:border-gray-600"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase bg-gray-200 border-b dark:border-gray-700 dark:text-gray-400 dark:bg-gray-700"
            >
              <th class="px-4 py-3">Name</th>
              <th class="px-4 py-3">Product</th>
              <th class="px-4 py-3">Paid</th>
              <th class="px-4 py-3">Pending</th>
              <th class="px-4 py-3">Time</th>
              <th class="px-4 py-3">Order by</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="entry in entries"
              :key="entry.nano_id"
              class="text-gray-700 dark:text-gray-400"
            >
              <td class="px-4 py-3">
                <div class="flex items-center text-sm">
                  <div>
                    <p class="font-semibold">{{ entry.customer.name }}</p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center text-sm">
                  <div>
                    <p class="font-semibold">{{ entry.product.name }}</p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3 text-sm">
                <span
                  class="px-2 py-1 font-semibold leading-tight text-green-700 capitalize bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                >
                  ₹{{ entry.paid }}
                </span>
              </td>
              <td class="px-4 py-3 text-sm">
                <span
                  class="px-2 py-1 font-semibold leading-tight text-red-700 capitalize bg-red-100 rounded-full dark:bg-red-700 dark:text-red-100"
                >
                  ₹{{ entry.pending }}
                </span>
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center text-sm">
                  <div>
                    <p class="font-semibold">
                      {{ entry.created_at | humanDate }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3">
                <div class="flex flex-col items-start text-sm">
                  <div>
                    <p class="font-medium">{{ entry.user.name }}</p>
                  </div>
                  <div>
                    <p class="font-thin">{{ entry.user.email }}</p>
                    <p class="text-xs font-thin">{{ entry.cafe.name }}</p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase bg-gray-100 border-t dark:border-gray-700 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-700"
      >
        <span class="flex items-center col-span-3">
          Showing
          {{
            pagination.meta
              ? `${pagination.meta.from}-${pagination.meta.to} of ${pagination.meta.total}`
              : null
          }}
        </span>
        <span class="col-span-2"></span>
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li v-if="pagination.links && pagination.links.prev">
                <button
                  class="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Previous"
                  @click="loadEntries(pagination.meta.current_page - 1)"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
              <li v-if="pagination.links && pagination.links.next">
                <button
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Next"
                  @click="loadEntries(pagination.meta.current_page + 1)"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
    <orders-modal v-model="orderDetail" v-if="orderDetail.open"></orders-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrdersModal from "./OrdersModal";
import isEmpty from "ramda/es/isEmpty";
export default {
  name: "EntriesLog",
  data() {
    return {
      cafe_id: "",
      orderDetail: {
        open: false,
        customer: {},
      },
    };
  },
  mounted() {
    this.loadEntries();
    if (isEmpty(this.cafes)) {
      this.loadCafes();
    }
  },
  computed: {
    ...mapGetters({
      auth: ["auth/getAuthUser"],
      cafes: ["cafe/getCafes"],
      entries: ["entry/getEntries"],
      pagination: ["entry/getPagination"],
    }),
  },
  components: { OrdersModal },
  methods: {
    loadCafes() {
      this.$store.dispatch("cafe/getCafes");
    },
    loadEntries(page = "") {
      this.$store.dispatch("entry/getEntries", { cafe: this.cafe_id, page });
    },
    openOrderDetail(customer) {
      this.orderDetail.customer = { ...customer };
      this.orderDetail.open = true;
    },
    setCafeFilter(cafe) {
      if (this.cafe_id !== cafe.id) {
        this.cafe_id = cafe.id;
      } else this.cafe_id = "";
      this.loadEntries();
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const today = new Date(date);
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style></style>
