<template>
  <transition name="modal-animate" @after-enter="showContent = true">
    <div
      class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
      v-if="value.open"
    >
      <!-- Modal -->
      <transition
        appear
        enter-active-class="transition ease-out duration-200"
        enter-class="opacity-0 transform translate-y-1/2"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0 transform translate-y-1/2"
      >
        <div
          class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-4xl"
          role="dialog"
          id="modal"
        >
          <!-- @click.away="closeModal" -->
          <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
          <header class="flex justify-end">
            <button
              class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700 focus:outline-none"
              aria-label="close"
              @click="closeModal"
            >
              <svg
                class="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </header>
          <!-- Modal body -->
          <div slot="modal_body" class="mt-4 mb-6 flex flex-col space-y-5">
            <!-- Modal title -->
            <div>
              <p class="mb-2 text-lg text-gray-700 dark:text-gray-300">
                Orders summary for
                <span class="font-semibold">{{ value.customer.name }}</span>
              </p>
              <p class="mb-2 text-gray-700 dark:text-gray-300">
                Total pending ₹{{ value.customer.total_pending }}
              </p>
            </div>
            <!-- Modal description -->
            <div class="w-full overflow-x-auto">
              <table class="w-full whitespace-no-wrap">
                <thead>
                  <tr
                    class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-700"
                  >
                    <th class="px-4 py-3">Name</th>
                    <th class="px-4 py-3">Paid</th>
                    <th class="px-4 py-3">Cafe</th>
                    <th class="px-4 py-3">Collected by</th>
                    <th class="px-4 py-3">Date</th>
                  </tr>
                </thead>
                <tbody
                  class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
                >
                  <tr
                    v-for="entry in value.customer.entries"
                    :key="entry.nano_id"
                    class="text-gray-700 dark:text-gray-400"
                  >
                    <td class="px-4 py-3">
                      <div class="flex items-center text-sm">
                        <div>
                          <p class="font-semibold">{{ entry.product.name }}</p>
                          <p class="text-xs text-gray-600 dark:text-gray-400">
                            ₹{{ entry.product.price }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="px-4 py-3 text-sm">
                      <span
                        class="px-2 py-1 font-semibold leading-tight capitalize rounded-full text-red-700 bg-red-100 dark:bg-red-700 dark:text-red-100"
                      >
                        ₹{{ entry.paid }}
                      </span>
                    </td>
                    <td class="px-4 py-3">
                      {{ entry.cafe.name }}
                    </td>
                    <td class="px-4 py-3 flex flex-col space-y-1">
                      <p>{{ entry.user.name }}</p>
                      <p>{{ entry.user.email }}</p>
                    </td>
                    <td class="px-4 py-3">
                      {{ entry.created_at | humanDate }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <footer
            class="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800"
          >
            <button
              @click="closeModal"
              class="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            >
              Close
            </button>
          </footer>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "OrdersModal",
  data() {
    return {};
  },
  components: {},
  computed: {},
  props: {
    value: {
      required: true,
      type: Object,
    },
  },

  methods: {
    closeModal() {
      this.value.open = false;
      this.value.customer = {};
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const today = new Date(date);
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style></style>
